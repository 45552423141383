.skypath {
  .main-container {
    background-color: #99a7d8;
  }
  .tariffs {
    padding-top: 140px;
    padding-bottom: 70px;
  }
  .image-block {
    padding: 104px 0;
    background-color: #f5f5f5;
    margin-bottom: 140px;
    img {
      padding: 0;
    }
  }
  &__profit {
    max-width: 1240px;
    margin: 0 auto;
    justify-content: center;
    h2 {
      font-size: 113px;
      line-height: 108px;
      color: #99a7d8;
      display: flex;
      text-transform: uppercase;
      margin-bottom: 0;
      text-align: center;
    }
    margin-bottom: 140px;
  }
  .order {
    padding-bottom: 70px;
  }
  .order-photo {
    margin-bottom: 20px;
  }
  .order-shots {
    padding-bottom: 70px;
    border: transparent;
    margin-bottom: 0;
    .image-block-dbl__right {
      background-color: #99a7d8;
      padding: 88px 0;
    }
  }
}
@media (max-width: 991.98px) {
  .skypath,
  .booking {
    .details {
      p {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 50px;
      }
      margin-bottom: 50px;
    }
    .tariffs {
      padding-top: 0;
      padding-bottom: 0;
    }
    .image-block {
      margin-bottom: 50px;
    }
    .order {
      padding-bottom: 0;
    }
    .order-photo {
      margin-bottom: 10px;
    }
    .order-shots {
      .image-block-dbl__right {
        padding: 40px 0;
      }
    }
  }
}
