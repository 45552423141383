.success {
  position: fixed;
  width: 100%;
  display: flex;
  height: 100%;
  z-index: 100;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  .close{
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  &__img {
    margin-bottom: 44px;
  }
  &__title {
    h2 {
      font-size: 24px;
      line-height: 36px;
      color: #219653;
    }
    margin-bottom: 32px;
  }
  &__subtitle {
    margin-bottom: 95px;

    p {
      font-size: 18px;
      line-height: 36px;
      color: #000000;
      font-family: "Roboto-Regular";
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    a {
      button {
        background: #ffc21d;
        border-radius: 5px;
        border: transparent;
        width: 170px;
        height: 48px;
        transition: 0.3s;
        &:hover {
          background: #ffb118;
        }
      }
    }
  }
}
@media (max-width: 991.98px){
  .success{
    &__title{
      margin-bottom: 20px;
      h2{
        text-align: center;
        font-size: 22px;
      }
    }
    &__subtitle{
      margin-bottom: 44px;
      p{
        font-size: 16px;
      }
    }
  }
}