.footer {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 100px 0 100px 0;
    border-top: 1px solid #e0e0e0;
  }
  &__column {
    padding: 0;
    display: flex;
    justify-content: center;
    img {
      width: 258px;
    }
  }
  &__cr {
    height: 40px;
    display: flex;
    justify-content: center;
    background: #f5f5f5;
    align-items: center;
    font-size: 13px;
    color: #828282;
    font-family: "Roboto-Light";
  }
  &__list {
    &-item {
      font-size: 15px;
      line-height: 38px;
      list-style-type: none;
      a {
        color: #000;
        font-family: "Roboto-Medium";
      }
    }
  }

  &__contacts {
    margin-top: 67px;
    &-list {
      padding: 0 !important;
      &-item {
        font-size: 17px;
        line-height: 24px;
        list-style-type: none;
        font-family: "Roboto-Light";
        display: flex;
        justify-content: center;
        &:first-child {
          font-family: "Roboto-Regular";
          font-weight: normal;
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 24px;
        }
        a {
          font-size: 16px;
          line-height: 28px;
          font-family: "Roboto-Regular";
        }
      }
    }
  }
  .button-call {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    padding-right: 40px;
    a {
      button {
        background: #ffc21d;
        border-radius: 5px;
        border: transparent;
        width: 170px;
        height: 48px;
      }
    }
  }
}
@media (max-width: 1440px) {
  .footer {
    &__container {
      max-width: 1170px;
    }
  }
}
@media (max-width: 1170px) {
  .footer {
    &__container {
      padding: 64px 0;
      max-width: 960px;
    }
  }
}
@media (max-width: 991.98px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
    &__container {
      padding: 40px 0 60px 0;
    }
    &__contacts {
      margin-top: 30px;
    }
    &__cr {
      font-size: 10px;
    }
  }
  .footer {
    &__container {
      padding-top: 40px;
      padding-bottom: 60px;
    }
    &__contacts {
      margin-top: 30px;
    }
    &__contacts-list {
      margin-bottom: 0;
    }
    &__contacts-list-item {
      font-size: 12px;
      line-height: 28px;
      fw &:first-child {
        font-size: 14px;
        line-height: 24px;
      }
    }
    &__column {
      img {
        width: 140px;
      }
    }
  }
}
