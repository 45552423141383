* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto-Medium";
}
.projects-page {
  #last-project {
    border: transparent;
  }
  color: #000000;
  &__container {
    max-width: 1440px;
    width: 100%;
    padding-top: 156px;
  }
  &__link {
    margin-top: 44px;
    position: relative;
    a {
      font-family: "Roboto-Medium";
      font-size: 17px;
      line-height: 24px;
      color: #000;
      margin-right: 8px;
      position: relative;
      transition: 0.3s;
      &:hover{
        text-decoration: none;
      }
      &:hover::before{
        display: block;
      }
      &:before {
        width: calc(100% - 30px);
        bottom: -3px;
        left: 0;
        content: "";
        height: 2px;
        background: #000;
        position: absolute;
        display: none;
      }
    }
  }
  &__item {
    max-width: 1440px;
    margin: 0;
    padding-top: 70px;
    padding-bottom: 70px;
    border-bottom: 1px solid #e0e0e0;
    &:first-of-type {
      padding-top: 0;
    }
  }
  &__text {
    padding: 0;
    h2 {
      font-size: 24px;
      line-height: 32px;
      max-width: 480px;
      margin-bottom: 12px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
      max-width: 480px;
      margin-bottom: 32px;
    }
  }
  &__img {
    padding: 0;
    img {
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.2);
      width: 100%;
      border-radius: 10px;
    }
  }
  &__title {
    margin-bottom: 40px;
  }
}
.main__popup-button {
  width: 42px;
  height: 42px;
  border-radius: 0px 8px 8px 0px;
  background: #ffc21d;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 90%;
  right: 0;
  z-index: 101;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #ffb118;
  }
}

@media (max-width: 1440px) {
  .projects-page {
    &__container {
      max-width: 1170px;
    }
  }
}
@media (max-width: 1170px) {
  .projects-page {
    &__container {
      max-width: 960px;
    }
    &__text {
      h2,
      p {
        max-width: 440px;
      }
    }
  }
}
@media (max-width: 991.98px) {
  .projects-page {
    &__container {
      padding: 0 20px;
    }
    &__title {
      padding-top: 75px;
      margin-bottom: 30px;
    }
    &__item {
      flex-direction: column-reverse;
      padding: 40px 0;
      &:last-of-type {
        padding-bottom: 64px;
      }
    }
    &__img {
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
    &__text {
      .text__title {
        margin-bottom: 20px;
      }
      h2,
      p {
        max-width: 100%;
      }
      p {
        margin: 0;
      }
    }
    &__link {
      margin-top: 22px;
    }
  }
}
