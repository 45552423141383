body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

.header {
    font-family: "Roboto-Medium";
    height: 100px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
    position: fixed;
    transition: 0.4s;
    z-index: 2;
    width: 100%;
    .main-c__button {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        padding-left: 37px;
        a {
            button {
                background: #ffc21d;
                border-radius: 5px;
                border: transparent;
                padding: 10px 24px;
                transition: 0.3s;
                font-family: 'Roboto-Medium';
                &:hover {
                    background: #ffb118;
                }
            }
        }
    }
    &__burger-menu {
        position: relative;
    }
    &__burger {
        padding: 0;
    }
    &__container {
        max-width: 1440px;
        width: 100%;
        margin: 0 auto !important;
        height: 100%;
    }
    &__nav {
        padding: 0;
    }
    .nav-list {
        padding: 0;
        margin: 0;
        &__item {
            list-style-type: none;
            font-size: 18px;
            line-height: 24px;
            margin: 0 34px;
            cursor: pointer;
            a {
                text-decoration: none;
                color: #000;
                transition: 0.3s;
                &:hover {
                    color: #828282;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__logo {
        padding: 0;
        cursor: pointer;
    }
    &__contacts {
        padding: 0;
        display: flex;
    }
    .contacts {
        &__phone {
            font-size: 19px;
            line-height: 24px;
        }
        &__order {
            a {
                font-size: 14px;
                text-decoration: none;
                line-height: 17px;
                color: #828282;
                font-family: "Roboto-Light";
                transition: 0.3s;
                &:hover {
                    color: #000000;
                }
            }
        }
        &__lang {
            font-size: 18px;
            line-height: 24px;
            color: #000;
            cursor: pointer;
            font-family: "Roboto-Medium";
            outline: none;
            background-color: #fff;
            appearance: none;
            border: transparent;
            transition: 0.3s;
            &:hover {
                text-decoration: none;
                color: #828282;
            }
        }
    }
    .nav-mobile {
        // padding-left: 20px;
    }
}

@media (max-width: 1440px) {
    .header {
        &__container {
            max-width: 1170px;
        }
    }
}

@media (max-width: 1171px) {
    .header {
        &__container {
            max-width: 960px;
        }
        .nav-list__item {
            margin: 0 24px;
            a {
                font-size: 16px;
            }
        }
        img {
            max-width: 194px;
        }
    }
    &__contacts,
    .contacts {
        &__lang {
            font-size: 16px;
        }
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 991.98px) {
    .header {
        .contacts__lang {
            font-size: 14px;
            line-height: 18px;
        }
        height: 52px;
        &__container {
            flex-direction: row-reverse;
            padding: 0 20px;
        }
        &__logo {
            cursor: pointer;
            img {
                max-width: 101px;
            }
        }
        &__burger {
            justify-content: flex-end;
            z-index: 100;
            .burger-item {
                width: 24px;
                height: 18px;
                display: block;
                position: relative;
                transition: 0.3s;
                span {
                    position: absolute;
                    background-color: #000;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    top: 8px;
                    transition: 0.3s;
                }
                &:before,
                &:after {
                    transition: 0.3s;
                    background-color: #000;
                    content: "";
                    width: 100%;
                    position: absolute;
                    height: 2px;
                    left: 0;
                }
                &:before {
                    top: 0;
                }
                &:after {
                    bottom: 0;
                }
            }
        }
        &__burger-menu {
            position: fixed;
            padding-top: 70px;
            width: 100vw;
            height: 100vh;
            transition: 0.3s;
            // transform: translate(50%, 50%);
            background-color: #ffc21d;
            left: -150%;
            top: -100%;
            .nav-mobile {
                // padding-left: 20px;
                li {
                    list-style-type: none;
                    text-align: center;
                    font-size: 24px;
                    a {
                        color: #000;
                    }
                }
            }
        }
    }
    .burger-item.active-burger span {
        transform: scale(0);
    }
    .burger-item.active-burger:before {
        transform: rotate(45deg);
        top: 8px !important;
    }
    .burger-item.active-burger:after {
        transform: rotate(-45deg);
        bottom: 8px !important;
    }
    .header__burger-menu.active-burger {
        top: 0;
        left: 0;
    }
}

@media (max-width: 767.98px) {}