@font-face{
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url('../fonts/Roboto-Light.ttf') format('truetype');
}
@font-face{
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url('../fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face{
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url('../fonts/Roboto-Regular.ttf') format('truetype');
}