.col-lg-4,
.col-lg-8,
.col-lg-7 {
  padding: 0;
}
.site,
.skypath,
.booking {
  margin: 0;
  padding: 0;
  color: #000000;
  &__container {
    height: 100%;
  }
  &__main,
  &__details,
  &__search-flights,
  &__booking,
  &__destination,
  &__schedule,
  &__schedule-photo,
  &__screenshots,
  &__faq,
  &__faq-photos,
  &__map,
  &__map-photo,
  &__visual,
  &__planes,
  &__navigation,
  .top-block,
  .text-block,
  .image-block,
  .image-block-dbl {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
  }
  .image-block-dbl,
  &__navigation,
  .text-block {
    flex-direction: row;
  }
  .image-block-dbl {
    &__left,
    &__right {
      img {
        max-height: 505px;
      }
    }
    .faq-photos {
      img {
        max-height: 250px;
      }
    }
  }
  .site-container,
  .skypath-container,
  .booking-container {
    width: 100%;
  }
  .main-container {
    background-color: #e06c5b;
    width: 100%;
  }
  .top-block {
    padding-top: 240px;
    margin-bottom: 167px !important;
    .row {
      margin: 0;
      &:first-child {
        max-height: 58px;
        margin-bottom: -30px;
      }
    }
    &__subtitle,
    &__subtitle span {
      font-size: 18px;
      line-height: 28px;
      color: #fff;
      padding: 0;
    }
    &__title {
      padding: 0;
      margin-bottom: 60px;
      h1 {
        font-size: 50px;
        line-height: 58px;
        color: #fff;
        margin: 0;
      }
    }
    &__link {
      padding: 0;
      padding-top: 220px;
      padding-bottom: 70px;
      display: flex;
      align-items: flex-end;
      a {
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        text-decoration: underline;
        align-items: center;
        margin-right: 6px;
      }
      img {
        padding-bottom: 5px;
      }
    }
    &__img {
      padding: 0;
      position: relative;

      img {
        width: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
        position: relative;
        transform: translate(0px, 92px);
      }
    }
  }
  .details {
    padding-bottom: 140px;
    border-bottom: 1px solid #e0e0e0;
  }
  .text-block {
    margin-bottom: 0px;
    &__title {
      h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &__text {
      p {
        font-size: 18px;
        line-height: 28px;
        font-family: "Roboto-Light";
      }
    }
  }
  .search-flights {
    padding-top: 140px;
    padding-bottom: 70px;
  }
  .booking-block {
    background-color: #f5f5f5;
    padding: 124px 0;
    margin-bottom: 140px;
    img {
      padding: 0;
    }
  }
  &__destination {
    font-size: 113px;
    line-height: 68px;
    color: #e06c5b;
    text-transform: uppercase;
    margin-bottom: 140px;
  }
  .schedule {
    margin-bottom: 70px;
  }
  .schedule-photo {
    background-color: #f5f5f5;
    padding: 104px 0;
    margin-bottom: 20px;
  }
  .image-block-dbl {
    padding-bottom: 140px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 140px;
    &__left {
      padding-top: 75px;
      padding-bottom: 105px;
      display: flex;
      justify-content: center;
      background-color: #f5f5f5;
      img {
        max-width: 245px;
      }
    }
    &__right {
      padding: 180px 0;
      display: flex;
      justify-content: center;
      img {
        max-width: 354px;
      }
    }
  }
  .screenshots {
    .image-block-dbl__right {
      background-color: #e06c5b;
    }
  }
  .faq {
    margin: 0 auto;
    margin-bottom: 70px;
  }
  .faq-photos {
    padding-bottom: 140px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 140px;
    flex-direction: row;
    &__item {
      background: #f5f5f5;
      padding: 130px 0;
      display: flex;
      justify-content: center;
    }
    img {
      max-width: 353px;
      max-height: 420px;
      width: 100%;
    }
  }
  .map {
    margin-bottom: 70px;
  }
  .map-photo {
    background-color: #f5f5f5;
    padding: 104px 141px;
    display: flex;
    justify-content: center;
    margin-bottom: 140px;
    img {
      max-width: 100%;
    }
  }
  .visual {
    padding-top: 140px;
    border-top: 1px solid #e0e0e0;
    margin-bottom: 70px;
  }
  .planes {
    padding-bottom: 70px;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .navigation {
    // border-bottom: 1px solid #e0e0e0;
    padding-bottom: 70px;
    display: flex;
    justify-content: space-between;
    &__prev,
    &__next {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    a {
      color: #000;
    }
    img {
      padding-bottom: 3px;
    }
    img:last-child {
      margin-left: 10px;
    }
    img:first-child {
      margin-right: 10px;
    }
  }
}
@media (max-width: 1441px) {
  .site,
  .skypath,
  .booking {
    &__main,
    &__details,
    &__search-flights,
    &__booking,
    &__destination,
    &__schedule,
    &__schedule-photo,
    &__screenshots,
    &__faq,
    &__faq-photos,
    &__map,
    &__map-photo,
    &__visual,
    &__planes,
    &__navigation,
    .top-block,
    .text-block,
    .image-block,
    .image-block-dbl {
      max-width: 1170px;
    }
  }
}
@media (max-width: 1171px) {
  .site,
  .skypath,
  .booking {
    display: flex;
    justify-content: center;
    .top-block,
    .text-block,
    .image-block,
    .image-block-dbl {
      max-width: 960px;
      width: 100%;
    }

    .top-block {
      &__subtitle {
        margin-bottom: 30px;
      }
      &__title,
      &__title-mobile {
        margin-bottom: 30px;
        padding: 0;
        h1,
        h2 {
          font-size: 34px;
          line-height: 40px;
          color: #fff;
        }
      }
      &__link {
        align-items: center;
      }
      &__img {
        margin-bottom: 30px;
        flex-direction: column;
        img {
          max-width: 100%;
          transform: translate(0px, 72px);
        }
      }
    }
    .map-photo {
      img {
        padding: 0;
      }
    }
  }
}
@media (max-width: 1170px) {
  .site,
  .skypath,
  .booking {
    &__main,
    &__details,
    &__search-flights,
    &__booking,
    &__destination,
    &__schedule,
    &__schedule-photo,
    &__faq,
    &__map,
    &__map-photo,
    &__visual {
      max-width: 960px;
    }
    .text-block {
      h2 {
        max-width: 300px;
      }
    }
  }
  @media (max-width: 991.98px) {
    .image-block-dbl {
      &__left,
      &__right {
        img {
          max-height: 250px;
        }
        #datepick {
          max-height: 207px;
        }
        #phone {
          max-height: 250px;
        }
      }
    }
    .site,
    .skypath,
    .booking {
      &__main,
      &__details,
      &__search-flights,
      &__booking,
      &__destination,
      &__schedule,
      &__schedule-photo,
      &__faq,
      &__map,
      &__map-photo,
      &__visual,
      .text-block {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 50px;
      }
      .faq-photos {
        &__item{
          align-items: center;
        }
        img {
          max-height: 250px;
          max-width: 190px;
        }
      }
      &__container {
        max-height: 704px;
      }
      .top-block {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 78px;
        margin-bottom: 64px !important;
        height: 100%;
        max-width: 100%;
        max-height: 675px;
        .row {
          &:first-child {
            max-height: 150px;
            margin-bottom: 0;
          }
        }
        &__img {
          img {
            transform: translate(0px, 0px);
          }
        }
        &__link {
          padding-bottom: 0;
          padding-top: 0;
          margin-bottom: 30px;
        }
      }
      .text-block {
        h2 {
          font-size: 18px;
          line-height: 40px;
        }
        &__text {
          p {
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
      .details {
        padding-bottom: 0;
        border: transparent;

        &__text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      .search-flights {
        padding-top: 50px;
        padding-bottom: 50px;

        &__text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      .booking-block {
        padding-top: 56px;
        padding-bottom: 52px;
        margin-bottom: 50px;
      }
      .schedule {
        margin-bottom: 50px;
        &__text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      .schedule-photo {
        padding-top: 48px;
        padding-bottom: 48px;
        margin-bottom: 10px;
      }
      .image-block-dbl {
        padding-bottom: 0;
        margin-bottom: 50px;
        &__left {
          padding-top: 40px;
          padding-bottom: 40px;
          flex-direction: column;
          align-items: center;
          img {
            max-width: 122px;
          }
        }
        &__right {
          padding: 62px 48px;
          flex-direction: column;
          align-items: center;
          img {
            max-width: 227px;
          }
        }
      }
      &__faq,
      .faq {
        margin-bottom: 50px;
        text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      &__faq-photos,
      .faq-photos {
        border: transparent;
        margin-bottom: 50px;
        padding-bottom: 0;
        &__item {
          padding: 52px 65px;
          flex-direction: column;
        }
      }
      &__map,
      .map {
        margin-bottom: 50px;
        h2 {
          br {
            display: none;
          }
        }
        &__text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
      }
      .map-photo {
        padding: 48px 24px;
        margin-bottom: 50px;
      }
      &__visual,
      .visual {
        padding-top: 0;
        border: transparent;

        &__text {
          padding-bottom: 50px;
          border-bottom: 1px solid #e0e0e0;
        }
        &__title {
          h2 {
            padding-top: 50px;
            border-top: 1px solid #e0e0e0;
          }
        }
      }
      &__planes,
      .planes {
        padding-bottom: 64px;
      }
    }
  }
  @media (max-height: 1024px) and (max-width: 768px){
    .details {
      padding-top: 55px !important;
    }
    .main-container{
      height: 675px;
    }
    .top-block__link{
      margin-top: 190px;
    }
  }
  @media (max-width: 840px) {
    .site {
      &__container {
        max-height: 690px;
      }
    }
  }
  @media (max-width: 767.98px) {
    .main-container{
      height: inherit;
    }
    .top-block__link{
      margin-top: inherit;
    }
    .detail{
      padding-top: inherit;
    }
    .site {
      &__container {
        max-height: 690px;
      }
    }
  }
}

