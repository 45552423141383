@import "fonts";
@import "~bootstrap/scss/bootstrap";
@import "header.scss";
@import "footer.scss";
@import "projects.scss";
@import "contact-form.scss";
@import "windrose-site.scss";
@import "skypath.scss";
@import "booking.scss";
@import "success.scss";

.wrapper {
  position: relative;
  overflow: hidden;
  font-family: "Roboto-Medium";
  color: #000;
}
.grecaptcha-badge {
  visibility: hidden;
}
body {
  overflow-x: hidden;
}
.hide {
  display: none !important;
}
.active-burger-rest {
  visibility: hidden;
}
.active-burger-container {
  background: #ffc21d;
}
.show-hide {
  top: 0 !important;
  left: 0 !important;
}
.main {
  background: url("/dist/img/bg-1.png");
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 80px;
  z-index: -10;
  &__popup-button {
    width: 42px;
    height: 42px;
    border-radius: 8px 0px 0px 8px;
    background: #ffc21d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 70%;
    right: 0;
    z-index: 101;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background: #ffb118;
    }
  }
  .main-c {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__title {
      h2 {
        font-size: 62px;
        max-width: 680px;
        text-align: center;
        line-height: 68px;
        margin-bottom: 38px;
        br {
          display: none;
        }
      }
      span {
        color: #ffc21d;
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      a {
        button {
          background: #ffc21d;
          border-radius: 5px;
          border: transparent;
          padding: 16px 34px;
          transition: 0.3s;
          &:hover {
            background: #ffb118;
          }
        }
      }
    }
    &__scroll-down {
      display: flex;
      align-items: center;
      margin-top: 120px;
      cursor: pointer;
      img {
        transform: translate(0);
        margin-right: 8px;
        position: relative;
      }
      span {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
  &__content {
    position: relative;
    max-width: 1440px;
    margin: 0 auto !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}
.header-scroll {
  transform: translateY(-100%);
}
.active-popup,
.active-popup-top {
  overflow: hidden;
  background-color: #fff;
}
body.active-popup{
  overflow: hidden;
}
.main__popup.active-popup {
  right: 0;
}
.top-popup.active-popup-top {
  top: 0;
}
@media (max-width: 991.98px) and (min-width: 767.98px) {
  .popup__row {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
  .figure-bg__block {
    padding: 0 20px;
  }
}
.about-us {
  max-height: 768px;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  padding-top: 140px;
  padding-bottom: 200px;
  &__container {
    max-width: 1440px;
    width: 100%;
  }
  &__img {
    padding: 0;
    padding-top: 20px;
  }
  &__content {
    flex-direction: column;
    align-items: start;
    padding: 0;
    h2 {
      font-size: 50px;
      line-height: 58px;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
      margin-bottom: 24px;
      max-width: 497px;
    }
    p:first-of-type {
      margin-bottom: 16px;
    }
  }
  &__link {
    cursor: pointer;
    margin-top: 58px;
    display: none;
    a {
      font-size: 17px;
      line-height: 24px;
      color: #000;
      text-decoration: none;
      transition: 0.3s;
      font-family: "Roboto-Medium";
      margin-right: 8px;
    }
  }
}

.ecommerce {
  max-height: 722px;
  height: 100%;
  position: relative;
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  &__content {
    flex-direction: column;
    padding: 0;
    h2 {
      margin-bottom: 24px;
      font-size: 50px;
      line-height: 58px;
      span {
        color: #ffc21d;
      }
    }
    h3 {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      max-width: 470px;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
    }
  }
  &__img {
    padding: 0;
    justify-content: center;
    padding-top: 40px;
  }
  &__figure {
    position: absolute;
    top: 200px;
    z-index: -1;
    display: none;
  }
}
.healthcare {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  &__img {
    padding: 0;
    justify-content: center;
  }
  &__content {
    flex-direction: column;
    padding: 0;
    h2 {
      margin-bottom: 58px;
      font-size: 50px;
      line-height: 58px;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      max-width: 470px;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
    }
  }
}
.transport {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  &__content {
    flex-direction: column;
    padding: 0;
    h3 {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      max-width: 470px;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
    }
  }
  &__img {
    padding: 0;
    justify-content: center;
  }
}
.tourism {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  &__content {
    flex-direction: column;
    padding: 0;
    h3 {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 32px;
    }
    p {
      max-width: 470px;
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
    }
  }
  &__img {
    padding: 0;
    padding-top: 20px;
    justify-content: center;
  }
}

.approach {
  background: #193898;
  padding: 0;
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0 200px 0;
    border-bottom: 1px solid #e0e0e0;
    .row {
      margin: 0;
    }
  }
  &__title {
    padding: 0;
    h2 {
      font-size: 50px;
      line-height: 58px;
      color: #ffffff;
      font-family: "Roboto-Medium";
      max-width: 435px;
      margin-bottom: 24px;
      span {
        color: #ffc21d;
      }
    }
  }
  &__content {
    p {
      font-family: "Roboto-Light";
      font-size: 18px;
      line-height: 28px;
      max-width: 500px;
      color: #fff;
      margin-bottom: 58px;
    }
  }
  &__img {
    padding-top: 90px;
    img {
      width: 100%;
    }
  }
  &__link {
    cursor: pointer;
    margin-top: 44px;
    a {
      font-size: 17px;
      line-height: 24px;
      color: #ffffff;
      text-decoration: underline;
      transition: 0.3s;
      font-family: "Roboto-Medium";
      margin-right: 8px;
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }
  }
}

.competence {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0 140px 0;
  }
  &__items {
    margin: 0;
  }
  &__title {
    margin-bottom: 70px;
    h2 {
      font-size: 50px;
      line-height: 58px;
      span {
        color: #ffc21d;
      }
    }
  }
  .item-c {
    flex-direction: column;
    margin-bottom: 105px;
    padding: 0;
    &__img {
      margin-bottom: 58px;
    }
    &__title {
      margin-bottom: 12px;
      max-width: 360px;
      font-size: 24px;
      line-height: 32px;
    }
    &__text {
      max-width: 468px;
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
    }
  }
}

.projects {
  background: #f5f5f5;
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0 130px 0;
  }
  &__row {
    padding: 70px 0;
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: transparent;
    }
  }
  .row {
    margin: 0;
  }
  &__title {
    font-size: 50px;
    line-height: 58px;
    span {
      color: #ffc21d;
    }
  }
  &__text {
    padding: 0;
    h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 12px;
      max-width: 320px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      font-family: "Roboto-Light";
      max-width: 468px;
      margin-bottom: 32px;
    }
  }
  &__link {
    margin-top: 44px;
    a {
      font-family: "Roboto-Medium";
      font-size: 16px;
      line-height: 24px;
      color: #000;
      margin-right: 8px;
      position: relative;
      text-decoration: none;
      &:before {
        width: calc(100% - 32px);
        bottom: -3px;
        left: 0;
        content: "";
        height: 2px;
        background: #000;
        position: absolute;
        display: none;
      }
      img{
        margin-left: 4px;
      }
    }
    a:hover{
      &:before{
        display: block;
      }
    }
  }
  &__img {
    padding: 0;
    display: flex;
    justify-content: end;
    flex-direction: column;
    img {
      width: 100%;
      box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }
  }
}

.clients {
  &__container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto !important;
    padding: 140px 0;
  }
  &__row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;
  }
  .row {
    margin: 0;
  }
  &__title {
    margin-bottom: 70px;
    h2 {
      font-size: 50px;
      line-height: 58px;
    }
    span {
      color: #ffc21d;
    }
  }
  &__img {
    max-width: 210px;
    max-height: 102px;
  }
}
@media (max-width: 1440px) {
  .main__container,
  .anout-us__container,
  .ecommerce__container,
  .healthcare__container,
  .transport__container,
  .tourism__container,
  .approach__container,
  .competence__container,
  .projects__container,
  .clients__container {
    max-width: 1170px;
  }
}
@media (max-width: 1170px) {
  .main__container,
  .anout-us__container,
  .ecommerce__container,
  .healthcare__container,
  .transport__container,
  .tourism__container,
  .approach__container,
  .competence__container,
  .projects__container,
  .clients__container {
    max-width: 960px;
  }
  .ecommerce,
  .healthcare,
  .transport,
  .tourism {
    &__img {
      img {
        max-width: 300px;
      }
    }
  }
  .item-c__text {
    max-width: 360px;
  }
    //1024
  .main {
    background: url("/dist/img/bg1-tablet.svg") top center no-repeat;
    background-size: cover;
  }
  .about-us {
    padding: 64px 0 64px 0;
    &__img {
      padding-top: 0;
      padding-bottom: 30px;
      img {
        max-width: 370px;
      }
    }
  }
  .competence {
    &__item:first-of-type,
    &__item:nth-of-type(2) {
      padding-right: 10px;
    }
  }

  .ecommerce__container,
  .healthcare__container,
  .transport__container,
  .tourism__container,
  .approach__container,
  .competence__container,
  .projects__container,
  .clients__container {
    padding: 64px 0 64px 0;
  }
  .projects {
    &__text {
      p {
        max-width: 380px;
      }
    }
  }
  .item-c__title {
    max-width: 201px;
  }
  .competence .item-c__text {
    max-width: 278px;
  }
  .competence__container {
    padding-bottom: 128px;
  }
  .clients__container {
    padding-bottom: 128px;
  }
  .clients__img {
    max-width: 160px;
    max-height: 70px;
  }
  .clients__title {
    margin-bottom: 64px;
  }
}
@media (max-width: 991.98px) {
  &__burger-menu {
    width: 100%;
    height: 100vh;
    transition: 0.2s;
    top: -120vh;
    left: -120vw;
    background-color: #ffc21d;
    .nav-mobile {
      &__item {
        list-style-type: none;
        a {
          color: #000;
          text-decoration: none;
          font-size: 20px;
          line-height: 40px;
          font-family: "Roboto-Medium";
        }
      }
    }
  }
  .main {
    padding-bottom: 0;
    background: url("/dist/img/bg1-tablet2.svg") center center no-repeat;
    background-size: cover;
    &__content {
      &__button {
        padding: 0;
      }
    }
    .main-c {
      padding: 0 20px;
      padding-bottom: 100px;
      &__scroll-down {
        padding: 0;
        margin-top: 80px;
      }
      &__title {
        // margin-bottom: 36px;
        h2 {
          font-size: 50px;
          line-height: 64px;
          br:nth-of-type(2) {
            display: inline;
          }
        }
      }
      &__button {
        a{
          
        }
      }
    }
    #main-cta{
      button{
        padding: 10px 24px;
      }
    }
  }

  .about-us {
    padding: 0;
    padding-top: 64px;
    padding-bottom: 88px;
    max-height: 1000px;
    &__container {
    }
    &__content {
      padding: 0 29px 0 21px;
      h2 {
        margin-bottom: 30px;
        font-size: 34px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
        max-width: 100%;
      }
      p:first-of-type {
        margin-bottom: 24px;
      }
      p:last-of-type {
        margin-bottom: 40px;
      }
    }
    &__link {
      margin-top: 40px;
      margin-bottom: 50px;
    }
    &__img {
      // padding: 0 25px 0 25px;
      display: grid;
      img {
        max-width: 300px;
      }
    }
  }
  .ecommerce {
    padding: 0;
    padding-top: 64px;
    padding-bottom: 0;
    max-height: 1000px;
    &__figure {
      display: none;
    }
    &__container {
      padding: 0;
      padding-bottom: 50px;
    }
    &__content {
      padding: 0 29px 0 21px;
      h2 {
        margin-bottom: 40px;
        font-size: 34px;
      }
      h3 {
        margin-bottom: 24px;
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px !important;
      }
      p:last-of-type {
        margin-bottom: 30px;
      }
    }
    &__link {
      margin-top: 0;
      margin-bottom: 50px;
    }
    &__img {
      display: grid;
    }
  }
  .healthcare {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 0;
    max-height: 1000px;
    &__container {
      padding: 0;
      padding-bottom: 50px;
    }
    &__content {
      padding: 0 29px 0 21px;
      h2 {
        margin-bottom: 40px;
      }
      h3 {
        margin-bottom: 24px;
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px !important;
      }
      p:last-of-type {
        margin-bottom: 30px;
      }
    }
    &__link {
      margin-top: 0;
      margin-bottom: 50px;
    }
    &__img {
      display: grid;
    }
  }
  .transport {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 0;
    max-height: 1000px;
    &__container {
      padding: 0;
      padding-bottom: 50px;
    }
    &__content {
      padding: 0 29px 0 21px;
      h2 {
        margin-bottom: 40px;
      }
      h3 {
        margin-bottom: 24px;
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px !important;
      }
      p:last-of-type {
        margin-bottom: 30px;
      }
    }
    &__link {
      margin-top: 0;
      margin-bottom: 50px;
    }
    &__img {
      display: grid;
    }
  }
  .tourism {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 0;
    max-height: 1000px;
    &__container {
      padding: 0;
      padding-bottom: 64px;
    }
    &__content {
      padding: 0 29px 0 21px;
      h2 {
        margin-bottom: 40px;
      }
      h3 {
        margin-bottom: 24px;
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 50px !important;
      }
      p:last-of-type {
        margin-bottom: 30px;
      }
    }
    &__link {
      margin-top: 0;
      margin-bottom: 50px;
    }
    &__img {
      display: grid;
    }
  }
  .approach {
    &__container {
      padding: 64px 29px 64px 21px;
      .row {
        margin: 0;
      }
    }
    &__title {
      h2 {
        margin-bottom: 30px;
        font-size: 34px;
        line-height: 40px;
      }
    }
    &__content {
      margin-bottom: 50px;
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
    &__img {
      padding: 0;
      width: 100%;
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .competence {
    &__container {
      padding-top: 64px;
      padding-bottom: 0;
      border: transparent;
    }
    &__title {
      margin: 0 22px;
      margin-bottom: 50px;
      h2 {
        font-size: 34px;
      }
    }
    &__items {
      margin: 0 22px;
    }
    &__item {
      margin-bottom: 50px !important;
      &:last-child {
        margin-bottom: 64px !important;
      }
    }
    .item-c {
      &__text {
        font-size: 16px;
        max-width: 278px;
        p {
        }
      }
      &__title {
        font-size: 20px;
      }
      &__img {
        margin-bottom: 24px;
        img {
          max-height: 70px !important;
        }
      }
    }
  }
  .projects {
    padding: 0 20px;
    &__container {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    &__title {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__row {
      padding-top: 0;
      padding-bottom: 48px;
      padding-top: 48px;
      border-bottom: 1px solid #e0e0e0;
      &:first-of-type {
        padding-top: 0;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
    &__img {
      margin-bottom: 34px;
      img {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
      }
    }
    &__text {
      padding-right: 30px;
      h3 {
        margin-bottom: 16px;
        font-size: 22px;
      }
      p {
        border-bottom: 1px solid #e0e0e0;
        margin-bottom: 0;
        padding-bottom: 40px;
        font-size: 16px;
        line-height: 24px;
        &:last-of-type {
          border: transparent;
          padding-bottom: 0;
        }
      }
    }
  }
  .clients {
    &__container {
      padding: 64px 20px;
    }
    &__title {
      margin-bottom: 50px;
      h2 {
        font-size: 34px;
      }
    }
    &__img {
      width: 100%;
      max-width: 100px;
      max-height: 70px;
      padding: 0;
      &:last-child {
        width: 80px;
      }
    }
  }
}
@media (max-width: 1280px) and (max-height: 768px) and (min-width: 1024px) and (min-height: 663px){
  .main{
    padding: 0;
    .main__content{
      .main-c{
        margin-top: 80px;
      }
    }
  }
}
@media (max-width: 767.98px) {
  .main {
    background: url("/dist/img/bg1-mobile.svg") center center no-repeat;
    &__content-text {
      padding-top: 105px;
    }
    .main-c {
      padding-top: 105px;
      &__title {
        h2 {
          font-size: 34px;
          line-height: 46px;
          margin-bottom: 32px;
          br:nth-child(2) {
            display: none;
          }
          br {
            display: inline;
          }
        }
      }
      &__button {
      }
      &__scroll-down {
        margin-top: 40px;
      }
    }
  }
  .about-us {
    background-color: #fff;
    margin-top: 0;
    padding-bottom: 0px;
    &__container {
      // flex-direction: column-reverse;
    }
    &__img {
      order: 1;
    }
    &__content {
      h2 {
        font-size: 40px;
        line-height: 46px;
      }
      p:first-of-type {
        margin-bottom: 30px;
      }
    }
    &__link {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .projects {
    &__text {
      p {
        max-width: 100%;
      }
    }
  }
  .ecommerce {
    &__container {
      // flex-direction: column;
      padding: 0 20px;
      border: transparent;
    }
    &__img {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 50px;
      order: 1;
    }
    &__content {
      padding: 0;
      h2 {
        font-size: 40px;
        line-height: 46px;
      }
      p {
        max-width: 100%;
      }
    }
  }
  .healthcare {
    &__container {
      flex-direction: column-reverse;
      padding: 0 20px;
      border: transparent;
    }
    &__content {
      padding: 0;
      p {
        max-width: 100%;
      }
    }
    &__img {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 50px;
    }
  }
  .transport {
    &__container {
      flex-direction: column;
      padding: 0 20px;
      border: transparent;
    }
    &__content {
      padding: 0;
      p {
        max-width: 100%;
      }
    }
    &__img {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 50px;
    }
  }
  .tourism {
    &__container {
      flex-direction: column-reverse;
      padding: 0 20px;
      border: transparent;
    }
    &__content {
      padding: 0;
      p {
        max-width: 100%;
      }
    }
    &__img {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 64px;
    }
  }
  .projects {
    &__row {
      flex-direction: column-reverse;
    }
    &__title {
      font-size: 34px;
      line-height: 40px;
    }
    &__text {
      padding-right: 0;
      h3 {
        font-size: 22px;
      }
      p {
      }
    }
    &__link {
      margin-top: 32px;
      a {
        font-size: 14px;
        line-height: 24px;
        &:before {
          width: 78px;
        }
      }
    }
  }
  .competence {
    &__item:first-of-type,
    &__item:nth-of-type(2) {
      padding-right: 0px;
    }
    &__item {
      margin-bottom: 48px !important;
      h3 {
        font-size: 22px;
      }
    }
    &__title {
      h2 {
        font-size: 34px;
        line-height: 40px;
      }
    }
    .item-c {
      &__img {
        margin-bottom: 32px;
      }
      &__title {
        margin-bottom: 20px;
      }
      &__text {
        p {
          max-width: 100%;
        }
      }
    }
  }
  .clients {
    &__row{
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 44px;
    }
    &__title {
      h2 {
        font-size: 34px;
        line-height: 40px;
      }
    }
    &__img {
      max-width: 120px;
    }
  }
}
@media (max-width: 700px) {
  .main {
    background: url("/dist/img/bg-1-xs.png") center center  no-repeat;
    background-size: cover;
    background-position: center;
  }
}
@media (max-width: 576px) {
  .about-us,
  .ecommerce,
  .healthcare,
  .transport,
  .tourism {
    &__img {
      img {
        max-width: 280px;
      }
    }
  }
}
