
.main__popup,
.top-popup {
  width: 101vw;
  height: 100vh;
  position: fixed;
  flex-direction: row-reverse;
  top: 0;
  right: -200%;
  z-index: 100;
  transition: 0.5s;
  margin: 0;
  color: #000000;
  overflow-x: hidden;
  overflow: scroll !important;
}
html.hide-scrollbar{
  scrollbar-width: none;
}
.hide-scrollbar {
  /*FireFox*/
  scrollbar-width: none;
  overflow: hidden;
  -ms-overflow-style: none;
  /*IE10+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.hide-scrollbar::-webkit-scrollbar {
  /*Chrome, Safari, Edge*/
  display: none;
  overflow: hidden;
}
.top-popup {
  left: 0;
  top: -200%;
}
.popup {
  &__close {
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 100;
    cursor: pointer;
  }
  &:-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

}
.popup__bg {
  background-color: #fff;
  z-index: 10;
  padding-bottom: 100px;
  img {
    padding-bottom: 50px;
  }
}
.popup__form {
  background: #fff;
  z-index: 10;
  padding-top: 110px;
  padding-left: 20px;
  &-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    border: transparent;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
    height: 40px;
    width: 100%;
    position: relative;
    outline: none;
    background-color: #fff;
    font-size: 16px;
    line-height: 36px;
    font-family: "Roboto-Light";
    color: #828282;
  }
  &-company,
  &-descr,
  &-phone,
  &-name,
  &-email,
  &-message {
    position: relative;
    input {
      border: transparent;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 30px;
      height: 40px;
      width: 100%;
      position: relative;
      outline: none;
      &:focus ~ label.form__label {
        top: -15px;
        font-size: 12px;
      }
    }

    label.error {
      position: absolute;
      left: 0;
      top: 30px;
      font-size: 12px;
      line-height: 36px;
      color: #eb5757;
    }

    &-file {
      display: none;
    }
    label {
      position: absolute;
      transition: 0.3s;
      top: 0;
      font-size: 16px;
      line-height: 36px;
      font-family: "Roboto-Light";
      color: #828282;
      pointer-events: none;
      left: 0;
    }
  }
  &-file {
    position: relative;
    input {
      display: none;
    }
    #file-size{
      font-size: 14px;
      line-height: 20px;
      color: #4F4F4F;
      max-width: 260px;
      position: absolute;
      top: 0;
      left: 50%;
      font-family: 'Roboto-Light';
    }
    label[for="file"] {
      border: 1px solid #ffc21d;
      width: 210px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      margin-bottom: 40px;
      pointer-events: auto;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background: #ffc21d;
      }
      img {
        margin-right: 9px;
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    max-width: 505px;
  }
  .popup__title {
    font-size: 50px;
    line-height: 58px;
    margin-bottom: 58px;
    color: #000000;
    span {
      color: #ffc21d;
    }
  }
  .main-p__button {
    display: flex;
    padding: 0;
    margin-bottom: 100px;
    input {
      background: #ffc21d;
      border-radius: 5px;
      border: transparent;
      transition: 0.3s;
      padding: 16px 34px;
      &:hover {
        background: #ffb118;
      }
    }
  }
  .popup__form {
    padding-top: 64px;
    .popup__title {
      margin-bottom: 45px;
    }
    label {
      font-size: 14px;
    }
    select {
      font-size: 14px;
    }
    &-company,
    &-descr,
    &-phone,
    &-name,
    &-email,
    &-message {
      input {
        margin-bottom: 24px;
      }
    }
  }
}
#message {
  margin-bottom: 40px;
}
.correct-form{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.5);
  &__block{
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;
    background: #fff;
  }
  &__title{

  }
}
@media (max-width: 1170px) {
  .popup {
    &__bg {
      img {
      }
    }
    &__close {
      right: 20px;
    }
  }
}
@media (max-width: 991.98px) {
  .popup {
    &__bg {
      img {
        padding-bottom: 50px;
      }
    }
    &__form {
      .form {
        max-width: 100%;
      }
      .main-p__button{
        padding-right: 0;
        input{
          padding: 10px 24px;
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .popup {
    &__close {
      right: 20px;
    }
    &__form {
      padding-right: 20px;
      padding-left: 20px;
      h2 {
        font-size: 34px !important;
        line-height: 40px !important;
      }
      form {
        // max-width: 100%;
      }
      .main-p__button {
        padding-right: 0;
      }
    }
  }
}
