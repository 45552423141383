.booking {
  .main-container {
    background-color: #0b4b88;
    .top-block {
      &__subtitle {
        p {
          max-width: 300px;
        }
      }
      h1 {
        br {
          display: none;
        }
      }
    }
  }
  .search-flights {
    padding-bottom: 0;
    margin-bottom: 70px;
  }
  .image-block {
    padding: 104px 0;
    background-color: #f5f5f5;
    margin-bottom: 140px;
    img {
      padding: 0;
    }
  }
  .details {
    margin-bottom: 0;
  }
  .booking-schema {
    max-width: 1240px;
    margin: 0 auto;
    margin-bottom: 140px;
    &__item {
      font-size: 113px;
      line-height: 108px;
      padding: 0;
      color: #0b4b88;
    }
  }
  .schedule {
    padding-top: 140px;
    border-top: 1px solid #e0e0e0;
  }
  .booking-shots {
    padding-bottom: 0;
    margin-bottom: 20px;
    border: transparent;
    &:last-of-type {
      padding-bottom: 140px;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 0;
    }
    .image-block-dbl {
      &__right {
        padding: 75px 0 105px 0;
        background-color: #f5f5f5;
        img {
          max-width: 245px;
        }
      }
    }
  }
  #last {
    padding-bottom: 70px;
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .booking {
    #last{
      padding-bottom: 50px;
    }
    .booking-shot {
      margin-bottom: 50px;
      border: transparent;
      padding-top: 48px;
      padding-bottom: 48px;
    }
    .schedule {
      padding-top: 0;
      border: transparent;
    }
    .booking-schedule {
      padding-top: 48px;
      padding-bottom: 48px;
      margin-bottom: 50px;
    }
    .booking-shots {
      &:last-of-type {
        padding-bottom: 50px;
      }
      .image-block-dbl__right {
        padding: 40px 0;
        img {
          max-width: 122px;
        }
      }
    }
    .search-flights {
      // padding-top: 0;
    }
  }
}
@media (max-width: 767.98px) {
  .booking {
    .top-block {
      &__title {
        h1 {
          font-size: 32px;
        }
      }
    }
  }
}
